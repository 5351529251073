import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactToPrint from "react-to-print";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TaskScoreGraph from "./TaskScoreGraph";
import GradeCard from "./GradeCard";
import StudentsResultsTable from "./StudentsResultsTable";
import ReturnToResults from "./ReturnToResults";
import RecommendationsButton from "./RecommendationsButton";
import ScoreToggleButton from "./ScoreToggleButton";
import AssessmentDropdown from "./AssessmentDropdown";
import PrintClassResults from "./PrintClassResults";
import ExportClassResults from "./ExportClassResults";
import ScoreInterpretationTable from "./ScoreInterpretationTable";
import PieThresholdGraph from "./PieThresholdGraph";
import ClassroomAnalysis from "./ClassroomAnalysis";
import StudentScoresBarChart from "./StudentScoresBarChart";
import StudentTaskScoresChart from "./StudentTaskScoresChart";
import TaskScoresComparisonChart from "./TaskScoresComparisonChart";
import StudentPerformancePieChart from "./StudentPerformancePieChart";
import NormativeCurveChart from "./NormativeCurveGraph";
import IndividualChild from "./IndividualChild";
import StandardScoresGraph from "./StandardScoresGraph";
import ScoreStatements from "./ScoreStatements";
import CandyBearAnalysisGraph from "./CandyBearAnalysisGraph";
import StudentPermanceExplanationTable from "./StudentPerformanceExplanationTable";
import StudentPerformancePrintViewAlphabetical from "./StudentPerformancePrintViewAlphabetical";
import StudentPerformancePrintViewScore from "./StudentPerformancePrintViewScore";

import {
  getResultsByStudent,
  getTask,
  getIndividualStudentResults,
} from "../services/APIClient";

function StudentsResultsOverview(props) {
  const {
    classDetails,
    classAssessments,
    gradeLevel,
    teachers,
    assessment,
    schoolid,
    teacherid,
  } = props;
  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState(
    []
  );
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedChildID, setSelectedChildID] = useState("");
  const [individualStudentResult, setIndividualStudentResult] = useState("");
  const [toggleScoreType, setToggleScoreType] = useState("");
  const [sortCriteria, setSortCriteria] = useState({
    field: null,
    order: null,
  });
  const [showAtRiskOnly, setShowAtRiskOnly] = useState(false);
  const [showAnalysisPrintOptions, setShowAnalysisPrintOptions] =
    useState(false);
  const [showInsightsPrintOptions, setShowInsightsPrintOptions] =
    useState(false);
  const [showChildPrintOptions, setShowChildPrintOptions] = useState(false);
  const [showPrintView, setShowPrintView] = useState(false); // State for toggle visibility
  const [showStudentPerformancePrintView, setShowStudentPerformancePrintView] =
    useState(false); // New state for print view visibility

  const [
    showStudentPerformanceScorePrintView,
    setShowStudentPerformanceScorePrintView,
  ] = useState(false);

  const classAnalysisRef = useRef();
  const taskScoeGraphRef = useRef();
  const pieThresholdWithAnalysisRef = useRef();
  const classInsightsRef = useRef();
  const studentScoresChartRef = useRef();
  const studentPerformanceChartRef = useRef();
  const taskScoreComparisonRef = useRef();
  const individualChildRef = useRef();
  const candyBearRef = useRef();
  const standardScoreGraphRef = useRef();
  const studentPerformancePieRef = useRef();
  const performanceTableRef = useRef();

  useEffect(() => {
    if (assessment && assessment.instances) {
      getResultsByStudent(assessment.instances).then((response) => {
        setAssessmentResultsByStudent(response);
      });
    }
  }, [assessment]);

  useEffect(() => {
    getIndividualStudentResults(selectedChildID)
      .then((response) => {
        setIndividualStudentResult(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedChildID]);

  // Prepare data for TaskScoreGraph
  const tasksData = useMemo(() => {
    const taskScores = {};

    assessmentResultsByStudent.forEach((student) => {
      const studentTasks = student.tasks || [];
      studentTasks.forEach((task) => {
        const taskName = task.name;
        const score = toggleScoreType ? task.groupPercentile : task.score * 100;

        if (!taskScores[taskName]) {
          taskScores[taskName] = [];
        }
        taskScores[taskName].push(score);
      });
    });

    // Calculate average scores
    Object.keys(taskScores).forEach((taskName) => {
      const scores = taskScores[taskName];
      const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
      taskScores[taskName] = averageScore.toFixed(2);
    });

    return taskScores;
  }, [assessmentResultsByStudent, toggleScoreType]);

  // Example usage with your thresholds
  const thresholds = {
    Rhyming: { task: 20, student: 25 },
    "Initial Phoneme": { task: 20, student: 20 },
    "Phoneme Blending": { task: 20, student: 20 },
    "Letter Name": { task: 20, student: 15 },
    Deletion: { task: 20, student: 20 },
    "Letter Sound": { task: 20, student: 20 },
    "Word Recognition": { task: 10, student: 15 },
    "Non-Word Recognition": { task: 10, student: 15 },
  };

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-6">
            <Row>
              <Col className="col-6">
                <h2 className="page-description-header">{classDetails.name}</h2>
              </Col>
              <Col className="col-4">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={classDetails.grade_level1}
                  gradeLevel2={classDetails.grade_level2}
                  trialClass={classDetails.trial_class}
                />
              </Col>
            </Row>
            <p>
              View the Results for class, click on a student to see a more
              detailed breakdown.
            </p>
          </Col>
          <Col className="col-4">
            <Row>
              <Col className="col-3"></Col>
              <Col className="col-3">
                <ReturnToResults
                  classDetails={classDetails}
                  teachers={teachers}
                  classAssessments={classAssessments}
                  assessment={assessment}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              </Col>
              <Col className="col-1"></Col>
              <Col className="col-4">
                <RecommendationsButton onResultsPage={true} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Tabs defaultActiveKey="results" id="results-tabs" className="mb-3">
          <Tab eventKey="results" title="My Class">
            <Row>
              <Col className="col-5">
                <h4 className="page-description-header">{assessment.name}</h4>
              </Col>
              <Col className="col-1"></Col>
              <Col className="col-6">
                <div className="d-flex align-items-center">
                  <h4 className="page-description-header">Select Assessment</h4>
                  <AssessmentDropdown
                    classAssessments={classAssessments}
                    classDetails={classDetails}
                    teachers={teachers}
                    assessmentName={assessment.name}
                    assessment={assessment}
                    teacherid={teacherid}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-5">
                <ScoreInterpretationTable />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="col-3">
                <PrintClassResults
                  classDetails={classDetails}
                  classAssessments={classAssessments}
                  gradeLevel={gradeLevel}
                  teachers={teachers}
                  assessment={assessment}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  toggleScoreType={toggleScoreType}
                  sortCriteria={sortCriteria}
                  showAtRiskOnly={showAtRiskOnly}
                />
              </Col>
              <Col className="col-3">
                {/* <ExportClassResults
                  classDetails={classDetails}
                  classAssessments={classAssessments}
                  gradeLevel={gradeLevel}
                  teachers={teachers}
                  assessment={assessment}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  toggleScoreType={toggleScoreType}
                /> */}
              </Col>
              <Col className="col-6">
                <ScoreToggleButton setScoreType={setToggleScoreType} />
              </Col>
            </Row>
            <Row>
              <Col>
                <StudentsResultsTable
                  classDetails={classDetails}
                  classAssessments={classAssessments}
                  gradeLevel={gradeLevel}
                  teachers={teachers}
                  assessment={assessment}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  toggleScoreType={toggleScoreType}
                  isPrintOut={false}
                  sortCriteria={sortCriteria}
                  showAtRiskOnly={showAtRiskOnly}
                  setSortCriteria={setSortCriteria}
                  setShowAtRiskOnly={setShowAtRiskOnly}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              </Col>
            </Row>
            <Row>
              <Col className="results-buttons"></Col>
            </Row>
          </Tab>
          <Tab eventKey="classAnalysis" title="Class Analysis">
            <Row>
              <Col>
                <button
                  className="blue-button"
                  onClick={() =>
                    setShowAnalysisPrintOptions(!showAnalysisPrintOptions)
                  }
                >
                  {showAnalysisPrintOptions
                    ? "Hide Print Options"
                    : "View Print Options"}
                </button>

                {showAnalysisPrintOptions && (
                  <div className="print-options">
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Full Class Analysis
                        </button>
                      )}
                      content={() => classAnalysisRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Task Score Graph
                        </button>
                      )}
                      content={() => taskScoeGraphRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Threshold Graph with Analysis
                        </button>
                      )}
                      content={() => pieThresholdWithAnalysisRef.current}
                    />
                  </div>
                )}
                <hr />
                <div ref={classAnalysisRef}>
                  <div ref={taskScoeGraphRef}>
                    <TaskScoreGraph tasksData={tasksData} />
                    <hr />
                  </div>
                  <div ref={pieThresholdWithAnalysisRef}>
                    <PieThresholdGraph
                      assessmentResults={assessmentResultsByStudent}
                      thresholds={thresholds}
                    />
                    <hr />
                    <ClassroomAnalysis
                      classid={classDetails.id}
                      assessmentResultsByStudent={assessmentResultsByStudent}
                      thresholds={thresholds}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="classInsights" title="Class Insights">
            <Row>
              <Col>
                <button
                  className="blue-button"
                  onClick={() =>
                    setShowInsightsPrintOptions(!showInsightsPrintOptions)
                  }
                >
                  {showInsightsPrintOptions
                    ? "Hide Print Options"
                    : "View Print Options"}
                </button>
                {showInsightsPrintOptions && (
                  <div className="print-options">
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Class Insights
                        </button>
                      )}
                      content={() => classInsightsRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Student Results by Subtest
                        </button>
                      )}
                      content={() => studentScoresChartRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Student Performance in 5 Subtests
                        </button>
                      )}
                      content={() => studentPerformanceChartRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Task Score Comparison
                        </button>
                      )}
                      content={() => taskScoreComparisonRef.current}
                    />
                  </div>
                )}
                <div ref={classInsightsRef}>
                  <div ref={studentScoresChartRef}>
                    <StudentScoresBarChart
                      assessmentResultsByStudent={assessmentResultsByStudent}
                    />
                  </div>
                  <hr />
                  <div ref={studentPerformanceChartRef}>
                    <StudentTaskScoresChart
                      assessmentResultsByStudent={assessmentResultsByStudent}
                    />
                  </div>
                  <hr />
                  <div ref={taskScoreComparisonRef}>
                    <TaskScoresComparisonChart
                      assessmentResultsByStudent={assessmentResultsByStudent}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="results2" title="Planning SET">
            <Row>
              <Col className="blue-button-container">
                <button
                  className="blue-button"
                  onClick={() => {
                    setShowPrintView(!showPrintView);
                    setShowStudentPerformancePrintView(!showPrintView); // Show print view when toggling
                  }}
                >
                  {showPrintView
                    ? "Hide Alphabetical Print View"
                    : "View Alphabetical Print View"}
                </button>
              </Col>
            </Row>
            {showStudentPerformancePrintView && (
              <>
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Alphabetical View
                    </button>
                  )}
                  content={() => studentPerformancePieRef.current}
                />
                <div ref={studentPerformancePieRef}>
                  <StudentPerformancePrintViewAlphabetical
                    assessmentResultsByStudent={assessmentResultsByStudent}
                    thresholds={thresholds}
                  />
                </div>
              </>
            )}
            {!showPrintView && (
              <StudentPerformancePieChart
                assessmentResultsByStudent={assessmentResultsByStudent}
                thresholds={thresholds}
                classDetails={classDetails}
                classAssessments={classAssessments}
                gradeLevel={gradeLevel}
                teachers={teachers}
                assessment={assessment}
                toggleScoreType={toggleScoreType}
                isPrintOut={false}
                sortCriteria={sortCriteria}
                showAtRiskOnly={showAtRiskOnly}
                setSortCriteria={setSortCriteria}
                setShowAtRiskOnly={setShowAtRiskOnly}
                schoolid={schoolid}
                teacherid={teacherid}
              />
            )}
            <hr></hr>
            <StudentPermanceExplanationTable ref={performanceTableRef} />
          </Tab>
          <Tab eventKey="results3" title="Individual Child">
            <button
              className="blue-button"
              onClick={() => setShowChildPrintOptions(!showChildPrintOptions)}
            >
              {showChildPrintOptions
                ? "Hide Print Options"
                : "View Print Options"}
            </button>
            {showChildPrintOptions && (
              <div className="print-options">
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Individual Child
                    </button>
                  )}
                  content={() => individualChildRef.current}
                />
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Standard Scores Graph
                    </button>
                  )}
                  content={() => standardScoreGraphRef.current}
                />
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Candy Bear Analysis
                    </button>
                  )}
                  content={() => candyBearRef.current}
                />
              </div>
            )}
            <IndividualChild
              assessmentResultsByStudent={assessmentResultsByStudent}
              setSelectedChild={setSelectedChild}
              selectedChild={selectedChild}
            />
            <hr></hr>
            <div ref={individualChildRef}>
              <div ref={standardScoreGraphRef}>
                <StandardScoresGraph selectedChild={selectedChild} />
                <ScoreStatements selectedChild={selectedChild} />
              </div>
              <hr></hr>
              <div ref={candyBearRef}>
                <CandyBearAnalysisGraph
                  selectedChild={selectedChild}
                  setSelectedChildID={setSelectedChildID}
                  individualStudentResult={individualStudentResult}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default StudentsResultsOverview;
