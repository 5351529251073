import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSchools } from "../services/APIClient";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import PaginationComponent from "./PaginationComponent";

import "./ClassStudents.css";

function ClassStudents(props) {
  let students = props.students;
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let qrCodeDetails = props.qrCodeDetails;
  let schoolid = props.schoolid;

  const [countryId, setCountryId] = useState(null);

  useEffect(() => {
    getSchools()
      .then((response) => {
        const matchingSchool = response.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;

          setCountryId(countryid); // Set country ID
        }
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [schoolid]);

  let classStudentsArray = [];
  if (students) {
    students.forEach((student) => {
      classStudentsArray.push(student);
    });
  }

  classStudentsArray.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  const navigate = useNavigate();

  const toStudentDetails = (student) => {
    navigate("/student/details", {
      state: {
        student: { student },
        classDetails: { classDetails },
        teachers: { teachers },
        qrCodeDetails: { qrCodeDetails },
        students: { students },
        schoolid: { schoolid },
      },
    });
  };

  let [paginationItems, setPaginationItems] = useState([]);
  let [itemsPerPage] = useState(8);

  const [currentPage, setCurrentPage] = useState(1);
  const startItemIndex = (currentPage - 1) * itemsPerPage;
  const endItemIndex = startItemIndex + itemsPerPage;
  const currentItems =
    classStudentsArray?.slice(startItemIndex, endItemIndex) || [];

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const irelandId = "07d45b79-24b1-435f-b82a-93861b23a86e";
  const englandId = "74bb2897-dbbe-41e3-b547-caee866545cf";

  return (
    <Row>
      <Col>
        <div>
          <h3 className="class-overview-table-header">
            Class Students ({classStudentsArray.length})
          </h3>
          {classStudentsArray.length <= itemsPerPage ? (
            ""
          ) : (
            <Row>
              <PaginationComponent
                itemsPerPage={itemsPerPage}
                totalItems={classStudentsArray.length}
                handlePageClick={handlePageClick}
              />
            </Row>
          )}
          {classStudentsArray.length === 0 ? (
            <Row className="roles">
              <h5 className="page-description-header">
                There are currently no students in your class.
              </h5>
              <h5 className="page-description-header">
                Select "Add Students" above to start adding your students.
              </h5>
            </Row>
          ) : (
            <Table className="custom-table">
              <thead className="table-header">
                <tr>
                  <th>Name</th>
                  <th className="header-th">ESL</th>
                  <th className="header-th">
                    Speech &<br />
                    Language
                  </th>
                  {countryId === irelandId && (
                    <>
                      <th className="header-th">ASD</th>
                      <th className="header-th">GDD</th>
                    </>
                  )}
                  {countryId === englandId && (
                    <>
                      <th className="header-th">ASD</th>
                      <th className="header-th">GDD</th>
                      <th className="header-th">SEND</th>
                    </>
                  )}
                  <th className="header-th">Edit/Change Class</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((classStudents, index) => {
                  return (
                    <tr
                      key={index}
                      className="table-row"
                      id={classStudents.id}
                      onClick={() => {
                        toStudentDetails(classStudents);
                      }}
                    >
                      <td className="table-data cursor-on">
                        {classStudents.name}
                      </td>
                      <td>
                        {classStudents.esl ? (
                          <img className="x-icon" src="/assets/imgs/tick.svg" />
                        ) : null}
                      </td>
                      <td>
                        {classStudents.sen ? (
                          <img className="x-icon" src="/assets/imgs/tick.svg" />
                        ) : null}
                      </td>
                      {countryId === irelandId && (
                        <>
                          <td>
                            {classStudents.asd ? (
                              <img
                                className="x-icon"
                                src="/assets/imgs/tick.svg"
                              />
                            ) : null}
                          </td>
                          <td>
                            {classStudents.gdd ? (
                              <img
                                className="x-icon"
                                src="/assets/imgs/tick.svg"
                              />
                            ) : null}
                          </td>
                        </>
                      )}
                      {countryId === englandId && (
                        <>
                          <td>
                            {classStudents.asd ? (
                              <img
                                className="x-icon"
                                src="/assets/imgs/tick.svg"
                              />
                            ) : null}
                          </td>
                          <td>
                            {classStudents.gdd ? (
                              <img
                                className="x-icon"
                                src="/assets/imgs/tick.svg"
                              />
                            ) : null}
                          </td>
                          <td>
                            {classStudents.send ? (
                              <img
                                className="x-icon"
                                src="/assets/imgs/tick.svg"
                              />
                            ) : null}
                          </td>
                        </>
                      )}
                      <td>
                        <img
                          src="/assets/imgs/edit-icon.svg"
                          className="edit-students-cog"
                          alt="Settings"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default ClassStudents;
