import React, { useState, useEffect } from "react";
import { withRouter } from "../utils/withRouter.js";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import {
  createClass,
  getCountryGrades,
  getSchools,
  getTeachers,
} from "../services/APIClient";
import SimpleNavigationButton from "./SimpleNavigationButton";
import ConfirmClassOwnerModal from "./ConfirmClassOwnerModal";

function CreateClassForm(props) {
  const [selectedGradeLevel, setSelectedGradeLevel] = useState(""); // Will be updated based on roles
  const [classTitle, setClassTitle] = useState("");
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [classTitleError, setClassTitleError] = useState("");
  const [gradeError, setGradeError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [classOwner, setClassOwner] = useState(null);
  const [countryId, setCountryId] = useState(null);

  const teacherId = props.teacherid;
  const schoolid = props.router.location.state.schoolid.schoolid;
  let teacherRoles = props.teacherRoles;

  console.log(teacherRoles)

  const defaultGradeLevel = () => {
    const lowerCasedRoles = teacherRoles.map(role => role.toLowerCase());
  
    // Prioritize checking for 'SET' or 'SENCO'
    if (
      lowerCasedRoles.some(role => role.includes("set")) ||
      lowerCasedRoles.some(role => role.includes("senco"))
    ) {
      console.log("Detected 'SET' or 'SENCO', defaulting to '' (empty)");
      return ""; // Defaulting to an empty string for the 'Select Class Level...' option
    }
  
    // Check for 'Junior Infant'
    if (lowerCasedRoles.some(role => role.includes("junior infant"))) {
      console.log("Setting default grade to Junior Infants");
      return "Junior Infants"; // Match with actual grade option
    }
  
    // Check for 'Senior Infant'
    if (lowerCasedRoles.some(role => role.includes("senior infant"))) {
      console.log("Setting default grade to Senior Infants");
      return "Senior Infants"; // Match with actual grade option
    }
  
    // Default to empty for all other cases
    return ""; 
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;
          setCountryId(countryid)
          const gradesResponse = await getCountryGrades(countryid);
          const { grade1, grade2 } = gradesResponse;
          setGradeLevelOptions([grade1, grade2]);

          const teachersResponse = await getTeachers(schoolid);
          setTeacherOptions(teachersResponse);

          // Set default grade level based on teacher roles
          const defaultGrade = defaultGradeLevel();
          if (defaultGrade) {
            setSelectedGradeLevel(defaultGrade);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [schoolid, teacherRoles]);

  useEffect(() => {
    if (submitted) {
      const isClassTitleValid = classTitle.length > 0;
      const isGradeValid = selectedGradeLevel.length > 0;

      setClassTitleError(isClassTitleValid ? "" : "Please enter a class name.");
      setGradeError(isGradeValid ? "" : "Please select a grade level.");
    }
  }, [classTitle, selectedGradeLevel, submitted]);

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitted(true);

    const isClassTitleValid = classTitle.length > 0;
    const isGradeValid = selectedGradeLevel.length > 0;

    if (!isClassTitleValid || !isGradeValid) {
      setClassTitleError(
        !isClassTitleValid ? "Please enter a class name." : ""
      );
      setGradeError(!isGradeValid ? "Please select a grade level." : "");
      return;
    }

    setShowModal(true);
  }

  function handleModalConfirm(selectedTeacherId) {
    console.log(
      "Selected Teacher ID in handleModalConfirm:",
      selectedTeacherId
    ); // Log the selected teacher ID

    const matchingGrade = gradeLevelOptions.includes(selectedGradeLevel);
    if (matchingGrade) {
      const trialClass = false;
      const grade = selectedGradeLevel;
      const formattedClassTitle = `24-${classTitle}`;

      const finalClassOwner = selectedTeacherId || teacherId;

      console.log("ClassOwner before createClass:", finalClassOwner);

      createClass(
        formattedClassTitle,
        grade,
        grade === gradeLevelOptions[0],
        grade === gradeLevelOptions[1],
        trialClass,
        false,
        finalClassOwner
      )
        .then(() => {
          // Only navigate if the user didn't select "createTeacher"

          setTimeout(() => {
            props.router.navigate("/classadmindashboard");
          }, 3000); // Delay for 3 seconds before navigating
        })
        .catch((error) => {
          console.error("Error creating class:", error);
        });
    }
    setShowModal(false);
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function handleSelectOwner(teacherId) {
    console.log("ClassOwner being set to:", teacherId);
    setClassOwner(teacherId);
  }

  // Inline styles for the text box
  const textBoxStyle = {
    border: "1px solid #ddd", // Light gray border
    borderRadius: "0.25rem", // Rounded corners
    padding: "1rem", // Padding
    marginBottom: "1rem", // Margin below
    fontSize: "18px", // Font size
    fontWeight: "bold", // Font weight
    textAlign: "center", // Center align text
  };

  console.log("countryID:", countryId)
  console.log("gradeLevelOptions:", gradeLevelOptions)
  

  return (
    <>
      <Form className="form form-detail" onSubmit={handleSubmit}>
        <div style={textBoxStyle}>
          <p>
            Please name classroom as it appears in your school MIS system e.g
            Aladdin / Arbor / i-SAMS - this will assist with reporting later.
          </p>
        </div>

        <Form.Group as={Row} className="mb-3" controlId="class-name">
          <Form.Label className="create-class-label" column sm="2">
            Class Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              placeholder="Class ABC..."
              size="md"
              type="text"
              value={`24-${classTitle}`}
              onChange={(e) => setClassTitle(e.target.value.slice(3))}
              isInvalid={submitted && !!classTitleError}
            />
            {submitted && classTitleError && (
              <Form.Control.Feedback type="invalid">
                {classTitleError}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="grade">
          <Form.Label className="create-class-label" column sm="2">
            Class Level
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              size="md"
              value={selectedGradeLevel}
              onChange={(e) => setSelectedGradeLevel(e.target.value)}
              isInvalid={submitted && !!gradeError}
            >
              <option value="">Select Class Level...</option>
              {/* {gradeLevelOptions.map((grade, index) => (
                <option key={index} value={grade}>
                  {grade}
                </option>
              ))} */}
              {gradeLevelOptions.map((grade, index) => {
                // Only render the first option if the country ID matches the restricted one
                if (countryId === '74bb2897-dbbe-41e3-b547-caee866545cf' && index === 1) {
                  return null; // Hide the 1st index option
                }
                return (
                  <option key={index} value={grade}>
                    {grade}
                  </option>
                );
              })}
            </Form.Control>
            {submitted && gradeError && (
              <Form.Control.Feedback type="invalid">
                {gradeError}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <div className="float-right">
          <SimpleNavigationButton
            path="/classadmindashboard"
            label="Cancel"
            colour="white"
          />
          <button className="green-button" type="submit">
            Create
          </button>
        </div>
      </Form>

      <ConfirmClassOwnerModal
        show={showModal}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
        teacherOptions={teacherOptions}
        onSelectOwner={handleSelectOwner}
      />
    </>
  );
}

export default withRouter(CreateClassForm);
