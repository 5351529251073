import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import {
  getAnswerDeletionReasonOptions,
  deleteAnswer,
  updateAnswerDeletionReason,
} from "../services/APIClient.js"; // Adjust the path as necessary

const DeleteAnswerModal = ({
  show,
  handleClose,
  taskName,
  studentName,
  assessmentName,
  handleDelete,
  answerId,
  teacherid,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    getAnswerDeletionReasonOptions().then(setOptions).catch(console.error);
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== "Other") {
      setOtherReason("");
    }
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };

  // const onDelete = async () => {
  //   // const reasonId = selectedOption === 'Other' ? null : selectedOption;
  //   const deletionReason = selectedOption === 'Other' ? otherReason : selectedOption;
  //   await deleteAnswer(answerId)
  //     .then((success) => {
  //       if (success) {
  //         handleDelete(taskName, deletionReason);
  //         setSuccessMessage(`${taskName} successfully deleted. Please rescan ${studentName} to retest ${taskName}.`);
  //         setTimeout(() => {
  //           handleClose();
  //           setSuccessMessage('');
  //         }, 3000);
  //       }
  //     })
  //     .catch(console.error);
  // };

  // const onDelete = async () => {
  //   const reasonId = selectedOption === 'Other' ? null : selectedOption;
  //   const deletionReason = selectedOption === 'Other' ? otherReason : selectedOption;
  //   try {
  //     const [deleteSuccess, updateSuccess] = await Promise.all([
  //       deleteAnswer(answerId),
  //       updateAnswerDeletionReason(answerId, reasonId, deletionReason, teacherid)
  //     ]);

  //     if (deleteSuccess && updateSuccess) {
  //       handleDelete(taskName, deletionReason);
  //       setSuccessMessage(`${taskName} successfully deleted. Please rescan ${studentName} to retest ${taskName}.`);
  //       //modal auto close to allow the user to see the success message
  //       setTimeout(() => {
  //         handleClose();
  //         setSuccessMessage('');
  //       }, 3000);
  //     } else {
  //       console.error("Failed to delete answer or update deletion reason");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting answer and updating reason:", error);
  //   }
  // };

  const onDelete = async () => {
    if (!selectedOption) {
      alert("Please select a reason for deletion.");
      return;
    }
    if (
      selectedOption ===
        options.find((option) => option.reason === "Other")?.id &&
      !otherReason
    ) {
      alert("Please provide a reason for deletion.");
      return;
    }

    const reasonId = selectedOption;
    const deletionReason =
      selectedOption === options.find((option) => option.reason === "Other")?.id
        ? otherReason
        : options.find((option) => option.id === selectedOption)?.reason;

    const answerDeletedData = {
      teacherid: teacherid,
      answerid: answerId,
      answerdeletionreasonid: reasonId,
      deletionreason: deletionReason,
    };

    try {
      const [deleteSuccess, updateSuccess] = await Promise.all([
        deleteAnswer(answerId),
        updateAnswerDeletionReason(answerDeletedData),
      ]);

      if (deleteSuccess && updateSuccess) {
        handleDelete(taskName, deletionReason);
        setSuccessMessage(
          `${taskName} successfully reset. Please rescan ${studentName} to retest ${taskName}.`
        );

        setSelectedOption("");
        setOtherReason("");

        setTimeout(() => {
          handleClose();
          setSuccessMessage("");
        }, 3000);
      } else {
        console.error("Failed to delete answer or update deletion reason");
      }
    } catch (error) {
      console.error("Error deleting answer and updating reason:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{successMessage ? "Success" : "Retake Task"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {successMessage ? (
          <Alert variant="success">{successMessage}</Alert>
        ) : (
          <>
            <div className="modal-info">
              <p>
                <strong>Task Name:</strong> {taskName}
              </p>
              <p>
                <strong>Student Name:</strong> {studentName}
              </p>
              <p>
                <strong>Assessment Name:</strong> {assessmentName}
              </p>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>
                <strong>Reason for Retake</strong>
              </Form.Label>
              <Form.Control
                as="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="" disabled>
                  Select a reason...
                </option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.reason}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {selectedOption ===
              options.find((option) => option.reason === "Other")?.id && (
              <Form.Group className="mt-3">
                <Form.Label>
                  <strong>Other Reason</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={otherReason}
                  onChange={handleOtherReasonChange}
                  className="other-answer-option-width"
                />
              </Form.Group>
            )}
          </>
        )}
      </Modal.Body>
      {!successMessage && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={onDelete}>
            Retake {taskName}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default DeleteAnswerModal;
