import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import {
  getClasses,
  updateStudentClassId,
  // getChangeClassReasonOptions, // Commenting out fetching change class reason options
  // updateClassChangeReason, // Commenting out updating class change reason
  updateStudent, // Import updateStudent function
} from "../services/APIClient";

const ChangeClassModal = ({
  show,
  handleClose,
  studentId,
  onClassChange,
  teacherId,
  oldClass,
}) => {
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  // const [changeReasons, setChangeReasons] = useState([]); // Commenting out change reasons state
  // const [selectedReasonId, setSelectedReasonId] = useState(""); // Commenting out selected reason state
  // const [otherReason, setOtherReason] = useState(""); // Commenting out other reason state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const classesData = await getClasses();
        // const reasonsData = await getChangeClassReasonOptions(); // Commenting out fetching reasons
        setClasses(classesData);
        // setChangeReasons(reasonsData); // Commenting out setting reasons
        console.log("Fetched Classes:", classesData); // Logging fetched classes
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleClassChange = (e) => {
    setSelectedClassId(e.target.value);
  };

  // Commenting out handleReasonChange function
  // const handleReasonChange = (e) => {
  //   setSelectedReasonId(e.target.value);
  //   if (
  //     e.target.value !==
  //     changeReasons.find((option) => option.reason === "Other")?.id
  //   ) {
  //     setOtherReason("");
  //   }
  // };

  // Commenting out handleOtherReasonChange function
  // const handleOtherReasonChange = (e) => {
  //   setOtherReason(e.target.value);
  // };

  const handleChangeClass = async () => {
    if (!selectedClassId) {
      alert("Please select a class.");
      return;
    }

    // Commenting out reason-related logic
    // if (!selectedReasonId) {
    //   alert("Please select a reason for the change.");
    //   return;
    // }
    // if (
    //   selectedReasonId ===
    //   changeReasons.find((option) => option.reason === "Other")?.id &&
    //   !otherReason
    // ) {
    //   alert("Please provide a reason for the change.");
    //   return;
    // }

    // const reasonId = selectedReasonId;
    // const classchangereason =
    //   selectedReasonId ===
    //   changeReasons.find((option) => option.reason === "Other")?.id
    //     ? otherReason
    //     : changeReasons.find((option) => option.id === selectedReasonId)
    //         ?.reason;

    const classChangedData = {
      studentId,
      teacherId,
      oldClass,
      newClass: selectedClassId,
      // Removed reason-related properties
      // reasonForChangeId: reasonId,
      // classchangereason,
      dateChanged: new Date().toISOString(),
    };

    try {
      // Update the student's class
      await updateStudentClassId(studentId, selectedClassId);
      // Commenting out class change reason update
      // await updateClassChangeReason(classChangedData);

      const newClassDetails = classes.find((cls) => cls.id === selectedClassId);
      const currentClassDetails = classes.find((cls) => cls.id === oldClass);

      onClassChange(newClassDetails); // Pass the new class details to the parent

      // Check if transitioning from special ed to non-special ed and update the student's status
      if (
        currentClassDetails?.specialed_class &&
        !newClassDetails?.specialed_class
      ) {
        const data = { isSpecialEd: false };
        await updateStudent(studentId, data);
        console.log("Student's special education status updated to false.");
      }
      // If transitioning into a special ed class, update the student's status to true
      else if (
        !currentClassDetails?.specialed_class &&
        newClassDetails?.specialed_class
      ) {
        const data = { isSpecialEd: true };
        await updateStudent(studentId, data);
        console.log("Student's special education status updated to true.");
      }

      setSuccessMessage(
        `Class successfully changed. Please notify ${studentId} of the new class.`
      );
      setSelectedClassId("");
      // Removed resetting reason-related states
      // setSelectedReasonId("");
      // setOtherReason("");

      setTimeout(() => {
        handleClose();
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error updating class change:", error);
      setError("Failed to update class change. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{successMessage ? "Success" : "Change Class"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {successMessage ? (
          <Alert variant="success">{successMessage}</Alert>
        ) : loading ? (
          <p>Loading data...</p>
        ) : error ? (
          <p>Error loading data: {error.message}</p>
        ) : (
          <Form>
            <Form.Group>
              <Form.Label>Select New Class</Form.Label>
              <Form.Control
                as="select"
                onChange={handleClassChange}
                value={selectedClassId}
              >
                <option value="" disabled>
                  Select a class
                </option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {/* Commenting out reason-related form controls
            <Form.Group>
              <Form.Label>Select Reason for Change</Form.Label>
              <Form.Control
                as="select"
                onChange={handleReasonChange}
                value={selectedReasonId}
              >
                <option value="" disabled>
                  Select a reason
                </option>
                {changeReasons.map((reason) => (
                  <option key={reason.id} value={reason.id}>
                    {reason.reason}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {selectedReasonId ===
              changeReasons.find((option) => option.reason === "Other")?.id && (
              <Form.Group className="mt-3">
                <Form.Label>
                  <strong>Other Reason</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={otherReason}
                  style={{ width: "100%" }}
                  onChange={handleOtherReasonChange}
                />
              </Form.Group>
            )}
            */}
          </Form>
        )}
      </Modal.Body>
      {!successMessage && (
        <Modal.Footer>
          <button className="white-button" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="blue-button"
            onClick={handleChangeClass}
            disabled={!selectedClassId}
          >
            Change Class
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ChangeClassModal;
