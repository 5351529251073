import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import TasksTable from "./TasksTable";
import ResultsCards from "./ResultsCards";
import GradeCard from "./GradeCard";
import ViewStudentsResultsButton from "./ViewStudentsResultsButton";
import ReturnToResultClassOverview from "./ReturnToResultClassOverview";
import ClassSettingsButton from "./ClassSettingsButton";

import { getClassResults, getStudentResults } from "../services/APIClient";

import "./ResultsOverview.css";

import AssessmentDropdown from "./AssessmentDropdown";
import RecommendationsButton from "./RecommendationsButton";

function ResultsOverview(props) {
  let classDetails = props.classDetails;
  let name = classDetails.name;
  let gradeLevel = props.gradeLevel;
  let classAssessments = props.classAssessments;
  let classid = props.classid;
  let teachers = props.teachers;
  let assessment = props.assessment;

  let sharedWith = props.sharedWith;
  let students = props.students;
  let qrCodeDetails = props.qrCodeDetails;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  // console.log(props)

  let assessmentName = assessment.name;

  const [assessmentInstanceResults, setAssessmentInstaceResults] = useState([]);

  let assessmentInstanceArray = [];
  classAssessments.forEach((values) => {
    assessmentInstanceArray.push(values.instances);
  });

  const assessmentInstanceID = assessmentInstanceArray.flat(1);

  useEffect(() => {
    getStudentResults(assessment.instances).then((response) => {
      setAssessmentInstaceResults(response);
    });
  }, [assessment]);

  console.log(assessmentInstanceResults, "getStudentResults");

  console.log("hiiiiiii");

  const [classResults, setClassResults] = useState([]);

  const classResultsValues = Object.values(classResults);

  useEffect(() => {
    getClassResults(classid).then((response) => {
      setClassResults(response);
    });
  }, [classid]);

  // return (
    // <div className="class-overview-container">
    //   <Container className="container-shape">
    //     <Row className="page-description-bar">
    //       <Col className="col-8">
    //         <h2 className="page-description-header">Class Results</h2>
    //         <p>Details of the {assessmentName} - Class Performance</p>
    //       </Col>
    //       <Col className="col-4">
    //         <Row>
    //           <Col className="col-6">
    //             <GradeCard
    //               gradeLevel={gradeLevel}
    //               gradeLevel1={gradeLevel1}
    //               gradeLevel2={gradeLevel2}
    //               trialClass={trialClass}
    //             />
    //           </Col>
    //           <Col className="col-6">
    //             <ClassSettingsButton
    //               classDetails={classDetails}
    //               teachers={teachers}
    //               sharedWith={sharedWith}
    //               gradeLevel={gradeLevel}
    //               name={name}
    //               students={students}
    //               qrCodeDetails={qrCodeDetails}
    //             />
    //           </Col>
    //         </Row>
    //       </Col>
    //     </Row>
    //     <hr></hr>
    //     <Row>
    //       <Col>
    //         <h3>{assessmentName}</h3>
    //       </Col>
    //       <Col style={{ textAlign: "right" }}>
    //         <h4>Results</h4>
    //       </Col>
    //       <Col>
    //         <AssessmentDropdown
    //           classAssessments={classAssessments}
    //           classDetails={classDetails}
    //           teachers={teachers}
    //           assessmentName={assessmentName}
    //         />
    //       </Col>
    //     </Row>
    //     <br></br>

    //     <Row>
    //       <TasksTable
    //         classResultsValues={classResultsValues}
    //         assessmentInstanceResults={assessmentInstanceResults}
    //       />
    //     </Row>
    //     <Row style={{ marginBottom: "2.5vh", marginTop: "2.5vh" }}>
    //       <ResultsCards classResultsValues={classResultsValues} />
    //     </Row>

    //     <Row>
    //       <Col className="results-buttons">
    //         <ReturnToResultClassOverview
    //           classDetails={classDetails}
    //           teachers={teachers}
    //           name={name}
    //           gradeLevel={gradeLevel}
    //         />
    //         <RecommendationsButton onResultsPage={true} />
    //         <ViewStudentsResultsButton
    //           classDetails={classDetails}
    //           gradeLevel={gradeLevel}
    //           classAssessments={classAssessments}
    //           teachers={teachers}
    //           assessment={assessment}
    //           assessmentInstanceID={assessmentInstanceID}
    //         />
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  // );
}

export default ResultsOverview;
