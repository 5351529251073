import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";

import "./SignUpForm.css";
import {
  getRoles,
  signupTeacher,
  getPendingTeacher,
  deletePendingTeacher,
} from "../services/APIClient";

function SignUpForm() {
  const [data, setData] = useState({
    password: "",
    passwordConfirm: "",
    country: "",
  });
  const [error, setError] = useState(null);
  const [roleDefs, setRoleDefs] = useState([]);
  const [pendingTeacherData, setPendingTeacherData] = useState();
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");
  const nav = useNavigate();
  const urlDecoded = new URLSearchParams(window.location.search);
  const pendingTeacherId = urlDecoded.get("ptid");

  useEffect(() => {
    getRoles()
      .then((res) => {
        setRoleDefs(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!pendingTeacherId) {
      // If ptid is not present, navigate to the root ("/") route
      nav("/");
      return;
    }

    getPendingTeacher(pendingTeacherId)
      .then((response) => {
        setPendingTeacherData(response);
        setData((prevData) => ({
          ...prevData,
          name: response?.name,
          email: response?.email,
          school: response?.schoolid || "",
          roles: response?.roles ? response.roles.split(",") : [],
          country: response?.schoolCountry || "", // Set the school's country in state
          sharedClassId: response?.sharedClassId || "", // Set sharedClassId
        }));
        // Save sharedClassId to local storage
        localStorage.setItem("sharedClassId", response?.sharedClassId || "");
      })
      .catch((error) => {
        console.error(error);
        nav("/");
        return;
      });
  }, [nav, pendingTeacherId]);

  const handleChange = (e, field) => {
    const { value, checked, name } = e.target;

    if (name === "roles") {
      const selectedRole = field;
      setData((prevData) => ({
        ...prevData,
        roles: checked
          ? [...prevData.roles, selectedRole]
          : prevData.roles.filter((role) => role !== selectedRole),
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }

    // Validate password
    if (field === "password") {
      const hasLetters = /[a-zA-Z]/.test(value);
      const hasNumbers = /\d/.test(value);
      const isValidLength = value.length >= 8;

      if (!isValidLength || !(hasLetters && hasNumbers)) {
        setPasswordValidationMessage(
          "Password must be at least 8 characters long and contain both letters and numbers."
        );
      } else {
        setPasswordValidationMessage("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const { password, passwordConfirm, roles, school, email, mobileNumber } =
      data;

    // Check password criteria
    const hasLetters = /[a-zA-Z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const isValidLength = password.length >= 8;

    if (!isValidLength || !(hasLetters && hasNumbers)) {
      setError(
        "Password must be at least 8 characters long and contain both letters and numbers."
      );
      return; // Prevent form submission
    }

    if (password !== passwordConfirm) {
      setError(
        "Passwords do not match. Please make sure both inputted passwords match."
      );
      setPasswordValidationMessage(""); // Clear password validation message
      return; // Prevent form submission
    }

    if (!school) {
      setError(
        "School ID is missing. Please try again or if issue still persists, contact your Alpaca Lead."
      );
      return;
    }
    signupTeacher({
      ...data,
      roles: roles || [],
      school: school,
      email: email,
      mobileNumber: mobileNumber,
    })
      .then((success) => {
        // console.log("User created successfully:", success);

        deletePendingTeacher(pendingTeacherId)
          .then(() => {
            // console.log("Pending teacher record deleted");
            localStorage.setItem('newUserSignup', 'true');

            nav("/masterdashboard");
          })
          .catch((error) => {
            console.error("Error deleting pending teacher record:", error);
            setError(
              "Something went wrong when creating a new account. Please try again or if issue still persists, contact your Alpaca Lead."
            );
          });
      })
      .catch((error) => {
        console.error("Error creating new user:", error);
        setError(
          "Something went wrong when creating a new account. Please try again or if issue still persists, contact your Alpaca Lead."
        );
      });
  };

  console.log(data.roles)

  let content = (
    <div>
      <br />
      <Row className="roles">
        <h6 className="page-description-header">
          Do you fulfill any of these roles in your school? Please tick any that
          apply.
        </h6>
        <Row className="roles-container">
          {roleDefs
            .filter((role) => role.countryid === data.country)
            .map((role) => (
              <Col className="col-5 " key={role.name}>
                <Form.Check
                  inline
                  label={role.name}
                  name="roles"
                  type="checkbox"
                  id={role.name}
                  defaultChecked={
                    !!data.roles && data.roles.includes(role.name)
                  }
                  onChange={(e) => handleChange(e, role.name)}
                />
              </Col>
            ))}
        </Row>
      </Row>
      <br />
      <Row className="roles">
        {data?.roles?.includes("Alpaca Lead") && (
          <>
            <div className="mb-6">
              <div className="mobile-fields mb-6">
                <h6 className="page-description-header">
                  Please enter your Referral Code.
                </h6>

                <div className="form-group row password-centre">
                  <label
                    className="col-sm-2 col-form-label signup-page-description-header"
                    htmlFor="referralCode"
                  >
                    Referral Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="referralCode"
                    name="referralCode"
                    value="" // Always set to empty, so nothing is captured
                    onChange={() => { }} // No-op to prevent changes
                    readOnly
                  />
                  <br />
                </div>
              </div>
            </div>
            <div className="mb-2"></div>

          </>
        )}


        <br />
        <br />


        <div className="mobile-fields">
          <h6 className="page-description-header">
            Please provide your mobile phone number. We will use this only to
            contact you regarding ALPACA.
          </h6>

          <div className="form-group row password-centre">
            <label
              className="col-sm-2 col-form-label signup-page-description-header"
              htmlFor="mobileNumber"
            >
              Mobile Number
            </label>
            <input
              type="number"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              value={data.mobileNumber}
              onChange={(e) => handleChange(e, "mobileNumber")}
              required
            />
            <br />
          </div>
        </div>

        <br />

        <div className="password-fields">
          <br />
          <h6 className="page-description-header">
            Please provide a password that you will use for your ALPACA account.
          </h6>
          <div>
            <div className="form-group row">
              <label
                className="col-sm-2 col-form-label signup-page-description-header"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={data.password}
                onChange={(e) => handleChange(e, "password")}
                required
              />
              <div className="text-danger">{passwordValidationMessage}</div>
            </div>
            <br />
            <div className="form-group row">
              <label
                className="col-sm-2 col-form-label signup-page-description-header"
                htmlFor="passwordConfirm"
              >
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                id="passwordConfirm"
                name="passwordConfirm"
                value={data.passwordConfirm}
                onChange={(e) => handleChange(e, "passwordConfirm")}
                required
              />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );

  return (
    <div className="signup-page-overview-container">
      <Container className="signup-container-shape">
        <Row className="signup-page-description-bar">
          <h2 className="page-description-header">Welcome {data.name}</h2>
          <p>
            Please provide the information below to complete your sign up to
            ALPACA
          </p>
        </Row>
        <Form onSubmit={handleSubmit}>
          <hr className="signup-hr" />
          <Row>{content}</Row>
          {error && <div className="alert alert-danger">{error}</div>}
          <Row>
            <Col className="signup-form-navigation-buttons">
              <button type="submit" className="blue-button button">
                Finish
              </button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

export default SignUpForm;
