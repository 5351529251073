import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { getClass, getStudents } from "../services/APIClient";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import StudentsResultsOverview from "../components/StudentsResultsOverview";
import OrientationPrompt from "../components/OrientationPrompt";

import { Container, Row, Col } from "react-bootstrap";

function StudentsResults(props) {
  const location = useLocation();

  let name = "Results";

  // Handle undefined state
  let classDetails = location.state?.classDetails?.classDetails || {};
  let gradeLevel = location.state?.gradeLevel?.gradeLevel || "";
  let classAssessments =
    location.state?.classAssessments?.classAssessments || [];
  // let teacherid = classDetails.teacherId || "";
  let teachers = location.state?.teachers?.teachers || [];
  let assessment = location.state?.assessment?.assessment || {};
  let assessmentInstanceID =
    location.state?.assessmentInstanceID?.assessmentInstanceID || "";

  // console.log(assessmentInstanceID)

  let schoolid = location.state?.schoolid?.schoolid;
  let teacherid = location.state?.teacherid?.teacherid;

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <StudentsResultsOverview
            classDetails={classDetails}
            classAssessments={classAssessments}
            // teachers={location.state.teachers}
            // sharedWith={sharedWith}
            // students={students}
            gradeLevel={gradeLevel}
            teachers={teachers}
            assessment={assessment}
            assessmentInstanceID={assessmentInstanceID}
            schoolid={schoolid}
            teacherid={teacherid}
            // name={location.state.name.name}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default StudentsResults;
