import React, { useEffect, useState } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  updateTeacherProfile,
  whoami,
  getRoles,
  getSchools,
} from "../services/APIClient";

import "./TeacherProfileForm.css";
import SimpleNavigationButton from "./SimpleNavigationButton";

function TeacherProfileForm(props) {
  const navigate = useNavigate();

  let profilePageRedirect = props.profilePageRedirect;

  console.log(profilePageRedirect)

  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [teacherEmail, setTeacherEmail] = useState("");
  const [roleDefs, setRoleDefs] = useState([]);
  const [countryid, setCountryid] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    whoami().then((response) => {
      const stakeholder = response.data.stakeholder;
      setSchoolid(stakeholder.schoolid);
      setTeacherid(stakeholder.id);
      setTeacherEmail(response.data.email);

      const names = stakeholder.name.split(" ");
      if (names.length >= 2) {
        setFirstName(names[0]);
        setLastName(names.slice(1).join(" "));
      }

      setSelectedRoles(stakeholder.additionalRoles || []);
      setContactNumber(stakeholder.mobileNumber);
    });
  }, []);

  useEffect(() => {
    getRoles()
      .then((res) => {
        setRoleDefs(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getSchools()
      .then((response) => {
        const matchingSchool = response.data.find((s) => s.id === schoolid);
        if (matchingSchool) {
          setCountryid(matchingSchool.countryid);
        }
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [schoolid]);

  function validateForm() {
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = "First name is required";
    if (!lastName.trim()) newErrors.lastName = "Last name is required";
    if (!contactNumber.trim())
      newErrors.contactNumber = "Contact number is required";
    if (selectedRoles.length === 0)
      newErrors.selectedRoles = "At least one role is required";
    return newErrors;
  }

  function handleRoleChange(event) {
    const selectedRole = event.target.value;
    setSelectedRoles((prev) => {
      if (prev.includes(selectedRole)) {
        return prev.filter((role) => role !== selectedRole);
      } else {
        return [...prev, selectedRole];
      }
    });
  }

  function handleSubmit() {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const teacherData = {
      name: `${firstName} ${lastName}`,
      additionalRoles: selectedRoles,
      mobileNumber: contactNumber,
    };

    updateTeacherProfile(schoolid, teacherid, teacherData)
      .then((response) => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error updating teacher:", error);
      });
  }

  return (
    <>
      <Form
        className="profile-form-detail form-teacher-profile"
        onSubmit={(e) => e.preventDefault()}
      >
        {profilePageRedirect ? (
          <h2 className="profile-page-description-header">
            Please update your 2024/25 details so we can show you relevant content.
          </h2>
        ) : (
          <h2 className="profile-page-description-header">Edit Your Profile</h2>
        )}
        {Object.keys(errors).length > 0 && (
          <Alert variant="danger">
            Please correct the following errors:
            <ul>
              {Object.values(errors).map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}
        <Form.Group as={Row} controlId="firstname" className="mb-3">
          <Form.Label column sm={2} className="profile-page-label">
            First Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              className="form-control"
              type="text"
              placeholder={firstName}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="lastname" className="mb-3">
          <Form.Label column sm={2} className="profile-page-label">
            Last Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              className="form-control"
              type="text"
              placeholder={lastName}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contactNumber" className="mb-5">
          <Form.Label column sm={2} className="profile-page-label">
            Contact Number
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              className="form-control"
              type="tel"
              placeholder={contactNumber || "Enter contact number"}
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-5">
          <Form.Label column sm={2} className="profile-page-label">
            Select your Roles:
          </Form.Label>
          <Col sm={10} className="grid-control">
            <div className="checkbox-grid">
              {roleDefs
                .filter((role) => role.countryid === countryid)
                .map((role) => (
                  <Form.Check
                    key={role.name}
                    type="checkbox"
                    id={`role-${role.name}`}
                    label={role.name}
                    value={role.name}
                    onChange={handleRoleChange}
                    checked={selectedRoles.includes(role.name)}
                  />
                ))}
            </div>
          </Col>
        </Form.Group>
        {/* Buttons Section */}
        <div className="button-group-edit-profile">
          <SimpleNavigationButton path={-1} label="Cancel" colour="white" />
          <button className="green-button" type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </Form>
    </>
  );
}

export default TeacherProfileForm;
