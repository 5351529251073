import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./ReportClassTable.css";
import LiteracyObservationsModal from "./LiteracyObservationsModal";
import ReadingScoreNotesModal from "./ReadingScoreNotesModal";
import AdditionalNeedsModal from "./AdditionalNeedsModal";
import DetailsOfSetReceivedModal from "./DetailsOfSetReceivedModal";
import SencoSupportPlanModal from "./SencoSupportPlanModal";
import SencoAdditionalNeedsModal from "./SencoAdditionalNeedsModal";
import PlaceOnContinuumModal from "./PlaceOnContinuumModal";
import SetSencoEalModal from "./SetSencoEalModal";
import ConfirmationModal from "./ConfirmationModal";
import SetEalModal from "./SetEalModal";
import SetSetModal from "./SetSetModal";
import SetSetNameModal from "./SetSetNameModal";
import SetAttendanceAffectingScoresModal from "./SetAttendanceAffectingScoresModal";

import ReportTableRow from "./JISIReportTableRow";
import {
  upsertSencoReport,
  getSencoReportByStudentId,
} from "../services/APIClient";
import SencoReportTableRow from "./SencoReportTableRow";
import PriorityConcernsModal from "./PriorityConcernsModal";
import ClassroomSupportPlanModal from "./ClassroomSupportPlanModal";

function SencoReportsClassTable(props) {
  const {
    students,
    showAtRiskOnly,
    setShowAtRiskOnly,
    teachers,
    teacherid,
    studentsReadingScore,
    classDetails,
    showSortedData,
  } = props;

  console.log(showAtRiskOnly, "SENCO");

  const dummyHeaderArray = [
    "Class",
    "SET",
    "Classroom Support Plan",
    "Details Of SET Received",
    "Priority Concerns",
    "Additional Needs",
    "Support Plan",
    "EAL",
    "Place on Continuum",
  ];

  console.log(teachers, "senco teachers");

  let className = classDetails.name;

  useEffect(() => {
    setCallClassName(className);
  });

  const [callClassName, setCallClassName] = useState("");

  const [showLiteracyModal, setShowLiteracyModal] = useState(false);
  const [showReadingScoreModal, setShowReadingScoreModal] = useState(false);
  const [showAdditionalNeedsModal, setShowAdditionalNeedsModal] =
    useState(false);
  const [showSupportPlanModal, setShowSupportPlanModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEalModal, setShowEalModal] = useState(false);
  const [showSetModal, setShowSetModal] = useState(false);
  const [
    showAttendanceAffectingScoresModal,
    setShowAttendanceAffectingScoresModal,
  ] = useState(false);

  const [showDetailsOfSetReceivedModal, setShowDetailsOfSetReceivedModal] =
    useState(false);

  const [showPlaceOnContinuumModal, setShowPlaceOnContinuumModal] =
    useState(false);

  const [showPriorityConcernsModal, setShowPriorityConcernsModal] =
    useState(false);

  const [showSencoSupportPlanModal, setShowSencoSupportPlanModal] =
    useState(false);

  const [showClassroomSupportPlanModal, setShowClassroomSupportPlanModal] =
    useState(false);

  const [showSencoAdditionalNeedsModal, setShowSencoAdditionalNeedsModal] =
    useState(false);

  const [showSetSetNameModal, setShowSetSetNameModal] = useState(false);

  const [showSencoEalModal, setShowSencoEalModal] = useState(false);

  const [currentStudentName, setCurrentStudentName] = useState("");
  const [currentStudentId, setCurrentStudentId] = useState("");

  // State to hold selections
  const [readingScoreNotes, setReadingScoreNotes] = useState([]);
  const [literacyObservations, setLiteracyObservations] = useState("");
  const [additionalNeeds, setAdditionalNeeds] = useState([]);
  const [setReceived, setSetReceived] = useState([]);
  const [priorityConcerns, setPriorityConcerns] = useState([]);
  const [sencoSupportPlan, setSencoSupportPlan] = useState([]);
  const [placeOnContinuum, setPlaceOnContinuum] = useState([]);
  const [classroomSupportPlan, setClassroomSupportPlan] = useState(false);
  const [sencoAdditionalNeeds, setSencoAdditionalNeeds] = useState([]);
  const [sencoEal, setSencoEal] = useState(false);
  const [eal, setEal] = useState(false);
  const [set, setSet] = useState(false);
  const [setName, setSetName] = useState("");
  const [attendanceAffectingScores, setAttendanceAffectingScores] =
    useState(false);

  const [sortCriteria, setSortCriteria] = useState({
    field: "name",
    order: "asc",
  });
  const [sortedData, setSortedData] = useState([]);

  const handleShowLiteracyModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowLiteracyModal(true);
  };

  const handleShowReadingScoreModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);

    setShowReadingScoreModal(true);
  };

  const handleShowAdditionalNeedsModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowAdditionalNeedsModal(true);
  };

  const handleShowSencoAdditionalNeedsModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowSencoAdditionalNeedsModal(true);
  };

  const handleShowClassroomSupportPlanModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowClassroomSupportPlanModal(true);
  };

  const handleShowDetailsOfSetReceivedModal = (studentName, studentId) => {
    console.log("HIIII");
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);

    setShowDetailsOfSetReceivedModal(true);
  };

  const handleShowPlaceOnContinuumModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);

    setShowPlaceOnContinuumModal(true);
  };

  const handleShowAttendanceAffectingScoresModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowAttendanceAffectingScoresModal(true);
  };

  const handleShowESetModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowSetModal(true);
  };

  const handleShowEalModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowEalModal(true);
  };

  const handleShowSencoEalModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowSencoEalModal(true);
  };

  const handleShowPriorityConcernsModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowPriorityConcernsModal(true);
  };

  const handleShowSencoSupportPlanModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowSencoSupportPlanModal(true);
  };

  const handleShowSetSetNameModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowSetSetNameModal(true);
  };

  const handleCloseLiteracyModal = () => setShowLiteracyModal(false);
  const handleCloseReadingScoreModal = () => setShowReadingScoreModal(false);
  const handleCloseAdditionalNeedsModal = () =>
    setShowAdditionalNeedsModal(false);
  const handleCloseSupportPlanModal = () => setShowSupportPlanModal(false);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleCloseEalModal = () => setShowEalModal(false);
  const handleCloseSetModal = () => setShowSetModal(false);
  const handleCloseAttendanceAffectingScoresModal = () =>
    setShowAttendanceAffectingScoresModal(false);
  const handleCloseDetailsOfSetReceivedModal = () =>
    setShowDetailsOfSetReceivedModal(false);

  const handleCloseSencoEalModal = () => setShowSencoEalModal(false);

  const handleClosePlaceOnContinuumModal = () =>
    setShowPlaceOnContinuumModal(false);

  const handleClosePriorityConcernsModal = () =>
    setShowPriorityConcernsModal(false);

  const handleCloseSencoSupportPlanModal = () =>
    setShowSencoSupportPlanModal(false);

  const handleCloseClassroomSupportPlanModal = () =>
    setShowClassroomSupportPlanModal(false);

  const handleCloseSencoAdditionalNeedsModal = () =>
    setShowSencoAdditionalNeedsModal(false);

  const handleCloseSetSetModal = () => setShowSetSetNameModal(false);

  const [lastSavedStudentId, setLastSavedStudentId] = useState();

  const initialState = {
    sencoAdditionalNeeds: [],
    sencoSupportPlan: [],
    sencoEal: false,
    classroomSupportPlan: false,
    setName: "",
    setReceived: [],
    priorityConcerns: [],
    placeOnContinuum: [],
  };

  const resetStateVariables = () => {
    if (currentStudentId !== lastSavedStudentId) {
      setSetName(initialState.setName);
      setSencoAdditionalNeeds(initialState.sencoAdditionalNeeds);
      setSencoSupportPlan(initialState.sencoSupportPlan);
      setSencoEal(initialState.sencoEal);
      setClassroomSupportPlan(initialState.classroomSupportPlan);
      setSetReceived(initialState.setReceived);
      setPriorityConcerns(initialState.priorityConcerns);
      setPlaceOnContinuum(initialState.placeOnContinuum);
    }
  };

  const getFilteredAndSortedStudents = () => {
    let filteredStudents = studentsReadingScore;

    // Filter the students if the 'At Risk' checkbox is selected
    if (showAtRiskOnly) {
      filteredStudents = filteredStudents.filter(
        (student) => student.readingScore > 0 && student.readingScore <= 40
      );
    }

    // Sort the filtered students based on the criteria (sorting logic)
    return sortStudents(filteredStudents, sortCriteria);
  };

  useEffect(() => {
    const filteredAndSortedStudents = getFilteredAndSortedStudents();
    setSortedData(filteredAndSortedStudents);
  }, [studentsReadingScore, showAtRiskOnly, sortCriteria]);

  const handleAtRiskFilterChange = (e) => {
    setShowAtRiskOnly(e.target.checked);
  };

  const handleConfirmChange = () => {
    console.log("Confirmation received, saving changes");
    handleSaveChanges();
    handleCloseConfirmationModal();
  };

  // Fetch JiSi Report when currentStudentId changes
  useEffect(() => {
    if (currentStudentId) {
      getSencoReportByStudentId(currentStudentId)
        .then((response) => {
          console.log("JiSi Report Data:", response);
          if (response && response.readingscorenotes !== undefined) {
            setReadingScoreNotes(response.readingscorenotes);
          }
          if (response && response.literacyobservations !== undefined) {
            setLiteracyObservations(response.literacyobservations);
          }
          if (response && response.additionalneeds !== undefined) {
            setAdditionalNeeds(response.additionalneeds);
          }
          if (response && response.supportplan !== undefined) {
            // setSupportPlan(response.supportplan);
          }
          if (response && response.eal !== undefined) {
            setEal(response.eal);
          }
          if (response && response.set !== undefined) {
            setSet(response.set);
          }
          if (response && response.attendanceaffectingscores !== undefined) {
            setAttendanceAffectingScores(response.attendanceaffectingscores);
          }
        })
        .catch((error) => console.error("Error fetching JiSi report:", error));
    }
  }, [currentStudentId]);

  useEffect(() => {
    const sortedStudents = sortStudents(studentsReadingScore, sortCriteria);
    setSortedData(sortedStudents);
  }, [studentsReadingScore, sortCriteria]);

  console.log(sortedData, "HIIII");

  const sortStudents = (students, criteria) => {
    return [...students].sort((a, b) => {
      if (criteria.field === "name") {
        return criteria.order === "asc"
          ? a.name?.localeCompare(b.name)
          : b.name?.localeCompare(a.name);
      } else if (criteria.field === "readingScore") {
        let scoreA =
          a.readingScore === "No completed tasks" || a.readingScore === "NA"
            ? -1
            : Number(a.readingScore);
        let scoreB =
          b.readingScore === "No completed tasks" || b.readingScore === "NA"
            ? -1
            : Number(b.readingScore);
        return criteria.order === "asc" ? scoreA - scoreB : scoreB - scoreA;
      }
    });
  };

  const handleSortChange = (field) => {
    setSortCriteria((prev) => ({
      field,
      order: prev.field === field && prev.order === "asc" ? "desc" : "asc",
    }));
  };

  const getSortIcon = (field) => {
    if (sortCriteria.field === field) {
      return sortCriteria.order === "asc"
        ? process.env.PUBLIC_URL + "/assets/imgs/desc-icon-active.png"
        : process.env.PUBLIC_URL + "/assets/imgs/asc-icon-active.png";
    }
    return process.env.PUBLIC_URL + "/assets/imgs/asc-icon-default.png";
  };

  const getReadingScoreClass = (score) => {
    if (score === 0) {
      return "low-color";
    } else if (score > 0 && score <= 20) {
      return "low-color";
    } else if (score > 20 && score <= 40) {
      return "med-color";
    } else if (score > 40 && score <= 100) {
      return "noRisk-color";
    } else {
      return "blah";
    }
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      classname: callClassName,
      setname: setName,
      classroomsupportplan: classroomSupportPlan,
      setreceived: setReceived,
      priorityconcerns: priorityConcerns,
      additionalneeds: sencoAdditionalNeeds,
      supportplan: sencoSupportPlan,
      eal: sencoEal,
      supportcontinuum: placeOnContinuum,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      setLastSavedStudentId(currentStudentId); // Update last saved student ID
      resetStateVariables();
      upsertSencoReport(reportData)
        .then((response) => {
          resetStateVariables();
          console.log("Upsert SENCO Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Row>
      <Col>
        <div className="table-responsive-wrapper">
          <Table className="custom-table">
            <thead className="table-header">
              <tr>
                <th colSpan={12} className="at-risk-filter-row">
                  <div className="at-risk-filter">
                    <label htmlFor="atRiskFilter">Show only "At-Risk"</label>
                    <input
                      type="checkbox"
                      name="atRiskFilter"
                      id="atRiskFilter"
                      checked={showAtRiskOnly}
                      onChange={handleAtRiskFilterChange}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"}
                      alt="0-20%"
                      className="risk-bar"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"}
                      alt="21-40%"
                      className="risk-bar"
                    />
                  </div>
                </th>
              </tr>
              <tr className="senco-table-row">
                <th
                  className="header-th-left senco-header-size-results"
                  onClick={() => handleSortChange("name")}
                >
                  <div className="header-content">
                    Student Name
                    <img
                      className="sort-icon"
                      src={getSortIcon("name")}
                      alt="Sort"
                    />
                  </div>
                </th>
                <th
                  className="header-th header-size-results"
                  onClick={() => handleSortChange("readingScore")}
                >
                  <div className="header-content">
                    Reading Score
                    <img
                      className="sort-icon"
                      src={getSortIcon("readingScore")}
                      alt="Sort"
                    />
                  </div>
                </th>
                {dummyHeaderArray.map((header, index) => (
                  <th key={index} className="header-th header-size-results">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((student) => (
                <SencoReportTableRow
                  key={student.id}
                  student={student}
                  teachers={teachers}
                  teacherid={teacherid}
                  className={className}
                  setSetName={setSetName}
                  setName={setName}
                  currentStudentId={currentStudentId}
                  setCurrentStudentId={setCurrentStudentId}
                  dummyHeaderArray={dummyHeaderArray}
                  getReadingScoreClass={getReadingScoreClass}
                  handleSaveChanges={handleSaveChanges}
                  handleShowDetailsOfSetReceivedModal={
                    handleShowDetailsOfSetReceivedModal
                  }
                  handleShowSencoSupportPlanModal={
                    handleShowSencoSupportPlanModal
                  }
                  handleShowPlaceOnContinuumModal={
                    handleShowPlaceOnContinuumModal
                  }
                  handleShowLiteracyModal={handleShowLiteracyModal}
                  handleShowReadingScoreModal={handleShowReadingScoreModal}
                  handleShowEalModal={handleShowEalModal}
                  handleShowSetModal={handleShowESetModal}
                  handleShowAttendanceAffectingScoresModal={
                    handleShowAttendanceAffectingScoresModal
                  }
                  handleShowAdditionalNeedsModal={
                    handleShowAdditionalNeedsModal
                  }
                  handleShowPriorityConcernsModal={
                    handleShowPriorityConcernsModal
                  }
                  handleShowClassroomSupportPlanModal={
                    handleShowClassroomSupportPlanModal
                  }
                  handleShowSencoAdditionalNeedsModal={
                    handleShowSencoAdditionalNeedsModal
                  }
                  handleShowSetSetNameModal={handleShowSetSetNameModal}
                  handleShowSencoEalModal={handleShowSencoEalModal}
                  readingScoreNotes={readingScoreNotes}
                  setReadingScoreNotes={setReadingScoreNotes}
                  literacyObservations={literacyObservations}
                  additionalNeeds={additionalNeeds}
                  eal={eal}
                  set={set}
                  attendanceAffectingScores={attendanceAffectingScores}
                />
              ))}
            </tbody>
          </Table>
        </div>
        <LiteracyObservationsModal
          show={showLiteracyModal}
          handleClose={handleCloseLiteracyModal}
          studentName={currentStudentName}
          literacyObservations={literacyObservations}
          setLiteracyObservations={setLiteracyObservations}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
        />
        <ReadingScoreNotesModal
          show={showReadingScoreModal}
          handleClose={handleCloseReadingScoreModal}
          studentName={currentStudentName}
          readingScoreNotes={readingScoreNotes}
          setReadingScoreNotes={setReadingScoreNotes}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
        />
        <AdditionalNeedsModal
          show={showAdditionalNeedsModal}
          handleClose={handleCloseAdditionalNeedsModal}
          studentName={currentStudentName}
          additionalNeeds={additionalNeeds}
          setAdditionalNeeds={setAdditionalNeeds}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
        />
        <DetailsOfSetReceivedModal
          show={showDetailsOfSetReceivedModal}
          handleClose={handleCloseDetailsOfSetReceivedModal}
          studentName={currentStudentName}
          setReceived={setReceived}
          setSetReceived={setSetReceived}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          callClassName={callClassName}
          setName={setName}
        />
        <PriorityConcernsModal
          show={showPriorityConcernsModal}
          handleClose={handleClosePriorityConcernsModal}
          studentName={currentStudentName}
          priorityConcerns={priorityConcerns}
          setPriorityConcerns={setPriorityConcerns}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          callClassName={callClassName}
          setName={setName}
        />
        <SencoSupportPlanModal
          show={showSencoSupportPlanModal}
          handleClose={handleCloseSencoSupportPlanModal}
          studentName={currentStudentName}
          sencoSupportPlan={sencoSupportPlan}
          setSencoSupportPlan={setSencoSupportPlan}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          callClassName={callClassName}
          setName={setName}
        />
        <PlaceOnContinuumModal
          show={showPlaceOnContinuumModal}
          handleClose={handleClosePlaceOnContinuumModal}
          studentName={currentStudentName}
          placeOnContinuum={placeOnContinuum}
          setPlaceOnContinuum={setPlaceOnContinuum}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          callClassName={callClassName}
          setName={setName}
        />
        <ClassroomSupportPlanModal
          show={showClassroomSupportPlanModal}
          handleClose={handleCloseClassroomSupportPlanModal}
          studentName={currentStudentName}
          classroomSupportPlan={classroomSupportPlan}
          setClassroomSupportPlan={setClassroomSupportPlan}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          callClassName={callClassName}
          setName={setName}
        />
        <SencoAdditionalNeedsModal
          show={showSencoAdditionalNeedsModal}
          handleClose={handleCloseSencoAdditionalNeedsModal}
          studentName={currentStudentName}
          sencoAdditionalNeeds={sencoAdditionalNeeds}
          setSencoAdditionalNeeds={setSencoAdditionalNeeds}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          callClassName={callClassName}
          setName={setName}
        />
        <SetSencoEalModal
          show={showSencoEalModal}
          handleClose={handleCloseSencoEalModal}
          studentName={currentStudentName}
          sencoEal={sencoEal}
          setSencoEal={setSencoEal}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          callClassName={callClassName}
          setName={setName}
        />
        <ConfirmationModal
          show={showConfirmationModal}
          handleClose={handleCloseConfirmationModal}
          studentName={currentStudentName}
          handleConfirmChange={handleConfirmChange}
          handleSaveChanges={handleSaveChanges}
        />
        <SetEalModal
          show={showEalModal}
          handleClose={handleCloseEalModal}
          studentName={currentStudentName}
          eal={eal}
          setEal={setEal}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
        />
        <SetSetNameModal
          show={showSetSetNameModal}
          handleClose={handleCloseSetSetModal}
          studentName={currentStudentName}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          setName={setName}
          setSetName={setSetName}
          teacherid={teacherid}
        />
        <SetSetModal
          show={showSetModal} // Correct the prop name
          handleClose={handleCloseSetModal} // Correct the close handler
          studentName={currentStudentName}
          set={set} // Correct state variable for SET
          setSet={setSet} // Correct setter function
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
        />
        <SetAttendanceAffectingScoresModal
          show={showAttendanceAffectingScoresModal} // Correct the prop name
          handleClose={handleCloseAttendanceAffectingScoresModal} // Correct the close handler
          studentName={currentStudentName}
          attendanceAffectingScores={attendanceAffectingScores} // Correct state variable for SET
          setAttendanceAffectingScores={setAttendanceAffectingScores} // Correct setter function
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
        />
      </Col>
    </Row>
  );
}

export default SencoReportsClassTable;
