import React, { useEffect, useState } from "react";
import "./ClassFilter.css";

function ClassFilter({ classes, setFilteredClasses, gradeLevelOptions, teacherRoles }) {

  const defaultGradeLevel = () => {
    console.log("Teacher Roles:", teacherRoles); // Debug teacherRoles input
  
    if (!teacherRoles) return "all"; // Handle case where teacherRoles might be undefined
  
    const lowerCasedRoles = teacherRoles.map(role => role.toLowerCase()); // Normalize roles to lowercase for flexible matching
  
    // Check if the role includes "set" or "senco"
    if (lowerCasedRoles.some(role => role.includes("set")) || lowerCasedRoles.some(role => role.includes("senco"))) {
      console.log("Detected 'SET' or 'SENCO' in roles, defaulting grade level to 'all'");
      return "all"; // Default to 'all' if "SET" or "SENCO" is found
    }
    // Match for "junior infant" substring, regardless of case or extra words
    else if (lowerCasedRoles.some(role => role.includes("junior infant"))) {
      console.log("Setting default grade to Junior Infant (grade1)");
      return "grade1";
    }
    // Match for "senior infant" substring, regardless of case or extra words
    else if (lowerCasedRoles.some(role => role.includes("senior infant"))) {
      console.log("Setting default grade to Senior Infant (grade2)");
      return "grade2";
    } 
    // If no match, default to "all"
    else {
      console.log("No match for teacher roles, defaulting to 'all'");
      return "all";
    }
  };
  


  const [selectedFilter, setSelectedFilter] = useState("24/25"); // Set "24/25" as the default filter
  const [activeButton, setActiveButton] = useState("24/25"); // Track the active filter button
  const [selectedGradeLevel, setSelectedGradeLevel] = useState(defaultGradeLevel()); // Use the default grade level based on roles

  console.log("Selected Filter:", selectedFilter); // Log selected filter
  console.log("Selected Grade Level:", selectedGradeLevel); // Log selected grade level on render

  useEffect(() => {
    console.log("Classes before filtering:", classes); // Log classes before filtering
    let filteredClasses = classes;

    if (selectedGradeLevel !== "all") {
      console.log(`Filtering by grade level: ${selectedGradeLevel}`);

      // Filter classes based on the selected grade level property (grade_level1 or grade_level2)
      filteredClasses = filteredClasses.filter((item) => {
        const gradeKey = `grade_level${selectedGradeLevel.split("")[selectedGradeLevel.length - 1]}`;
        console.log(`Checking class ${item.id} for grade key ${gradeKey}`);
        const isGradeLevel = item[gradeKey] === true;
        return isGradeLevel;
      });
    }

    // Apply the selected view filter
    switch (selectedFilter) {
      case "all":
        console.log("No date filter applied (all)");
        break;
      case "22/23":
        filteredClasses = filteredClasses.filter((item) => {
          const isInRange = new Date(item.date_updated) < new Date("2023-07-31");
          console.log(`Class ${item.id} (22/23 filter):`, isInRange);
          return isInRange;
        });
        break;
      case "23/24":
        filteredClasses = filteredClasses.filter((item) => {
          const dateUpdated = new Date(item.date_updated);
          const isInRange = dateUpdated >= new Date("2023-07-31") && dateUpdated < new Date("2024-07-31");
          console.log(`Class ${item.id} (23/24 filter):`, isInRange);
          return isInRange;
        });
        break;
      case "24/25":
        filteredClasses = filteredClasses.filter((item) => {
          const dateUpdated = new Date(item.date_updated);
          const isInRange = dateUpdated >= new Date("2024-07-31") && dateUpdated < new Date("2025-07-31");
          console.log(`Class ${item.id} (24/25 filter):`, isInRange);
          return isInRange;
        });
        break;
      default:
        break;
    }

    console.log("Classes after filtering by year:", filteredClasses); // Log filtered classes by year

    // Sort classes so that those needing to "Assign New Classroom teacher" come first
    filteredClasses.sort((a, b) => {
      const aNeedsAssign = a.name.includes("Assign New Classroom teacher");
      const bNeedsAssign = b.name.includes("Assign New Classroom teacher");
      return bNeedsAssign - aNeedsAssign;
    });

    console.log("Final filtered and sorted classes:", filteredClasses); // Log the final result after sorting

    // Pass the filtered and sorted classes back to the parent component whenever the filters change
    setFilteredClasses(filteredClasses);

    // Update the active button
    setActiveButton(selectedFilter);
  }, [selectedFilter, selectedGradeLevel, classes, setFilteredClasses, teacherRoles]);

  const gradeLevelOptionsArray = gradeLevelOptions
    ? Object.keys(gradeLevelOptions[0] || {})
      .filter((key) => key.startsWith("grade"))
      .slice(0, 2) // Slice to get only the first two options
    : [];

  console.log("Grade Level Options Array:", gradeLevelOptionsArray); // Log the dropdown options

  return (
    <>
      <div className="filter-container">
        <div className="filter-buttons">
          <button
            className={`light-blue-button-filter ${activeButton === "24/25" ? "active" : ""}`}
            onClick={() => {
              console.log("24/25 button clicked");
              setSelectedFilter("24/25");
            }}
          >
            24/25
          </button>
          <button
            className={`light-blue-button-filter ${activeButton === "23/24" ? "active" : ""}`}
            onClick={() => {
              console.log("23/24 button clicked");
              setSelectedFilter("23/24");
            }}
          >
            23/24
          </button>
          <button
            className={`light-blue-button-filter ${activeButton === "22/23" ? "active" : ""}`}
            onClick={() => {
              console.log("22/23 button clicked");
              setSelectedFilter("22/23");
            }}
          >
            22/23
          </button>
          <button
            className={`light-blue-button-filter ${activeButton === "all" ? "active" : ""}`}
            onClick={() => {
              console.log("All button clicked");
              setSelectedFilter("all");
            }}
          >
            All
          </button>
        </div>
        <div className="filter-dropdown">
          <label htmlFor="gradeLevelFilter">Grade Level Filter: </label>
          <select
            id="gradeLevelFilter"
            value={selectedGradeLevel}
            onChange={(e) => {
              console.log(`Grade level changed to: ${e.target.value}`);
              setSelectedGradeLevel(e.target.value);
            }}
          >
            <option value="all">All</option>
            {gradeLevelOptionsArray.map((key) => (
              <option key={key} value={key}>
                {gradeLevelOptions[0][key]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default ClassFilter;
