import React, { useState, useEffect } from "react";
import "./ReportTableRow.css";
import {
  upsertSencoReport,
  getSencoReportByStudentId,
} from "../services/APIClient";

function SencoReportTableRow({
  student,
  dummyHeaderArray,
  getReadingScoreClass,
  handleShowLiteracyModal,
  handleShowAdditionalNeedsModal,
  handleShowSencoAdditionalNeedsModal,
  handleShowDetailsOfSetReceivedModal,
  handleShowPriorityConcernsModal,
  handleShowSencoSupportPlanModal,
  handleShowPlaceOnContinuumModal,
  handleShowClassroomSupportPlanModal,
  handleShowSencoEalModal,
  handleShowSetSetNameModal,
  handleShowSetModal,
  handleShowAttendanceAffectingScoresModal,
  teachers,
  teacherid,
}) {
  const [literacyObservations, setLiteracyObservations] = useState("");
  const [additionalNeeds, setAdditionalNeeds] = useState([]);
  const [supportPlan, setSupportPlan] = useState([]);
  const [eal, setEal] = useState(false);
  const [set, setSet] = useState(false);
  const [attendanceAffectingScores, setAttendanceAffectingScores] =
    useState(false);
  const [setReceived, setSetReceived] = useState([]);
  const [priorityConcerns, setPriorityConcerns] = useState([]);
  const [sencoSupportPlan, setSencoSupportPlan] = useState([]);
  const [placeOnContinuum, setPlaceOnContinuum] = useState([]);
  const [classroomSupportPlan, setClassroomSupportPlan] = useState(false);
  const [sencoAdditionalNeeds, setSencoAdditionalNeeds] = useState([]);
  const [sencoEal, setSencoEal] = useState(false);
  const [callClassName, setCallClassName] = useState("");
  const [setName, setSetName] = useState("");
  const [currentStudentId, setCurrentStudentId] = useState("");

  const [literacyButtonClicked, setLiteracyButtonClicked] = useState(false);
  const [additionalNeedsButtonClicked, setAdditionalNeedsButtonClicked] =
    useState(false);
  const [
    detailsOfSetReceivedButtonClicked,
    setDetailsOfSetReceivedButtonClicked,
  ] = useState(false);
  const [priorityConcernsButtonClicked, setPriorityConcernsButtonClicked] =
    useState(false);
  const [sencoSupportPlanButtonClicked, setSencoSupportPlanButtonClicked] =
    useState(false);
  const [classroomSupportPlanModalClicked, setClassroomSupportPlanClicked] =
    useState(false);
  const [placeOnContinuumButtonClicked, setPlaceOnContinuumButtonClicked] =
    useState(false);
  const [ealModalButtonClicked, setEalModalButtonClicked] = useState(false);
  const [setModalButtonClicked, setSetModalButtonClicked] = useState(false);
  const [attendanceModalButtonClicked, setAttendanceModalButtonClicked] =
    useState(false);

  const filteredTeachers = teachers.filter(
    (teacher) =>
      teacher.additionalRoles?.includes("SENCO") ||
      teacher.additionalRoles?.includes("SET")
  );

  useEffect(() => {
    if (setName && currentStudentId) {
      const reportData = {
        studentid: currentStudentId,
        teacherid: teacherid,
        setname: setName,
      };

      console.log("Saving report data:", reportData);

      upsertSencoReport(reportData)
        .then((response) => {
          console.log("Upsert SENCO Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      if (!currentStudentId) {
        console.log("No student selected. Skipping save operation.");
      }
      if (!setName) {
        console.log("No set name provided. Skipping save operation.");
      }
    }
  }, [setName, currentStudentId, teacherid]);

  useEffect(() => {
    if (sencoEal !== null && currentStudentId) {
      console.log(sencoEal, "senco EAL");
      const reportData = {
        studentid: currentStudentId,
        teacherid: teacherid,
        eal: sencoEal,
      };

      console.log("Saving report data:", reportData);

      upsertSencoReport(reportData)
        .then((response) => {
          console.log("Upsert SENCO Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      if (!currentStudentId) {
        console.log("No student selected. Skipping save operation.");
      }
      if (sencoEal === null) {
        console.log("No set name provided. Skipping save operation.");
      }
    }
  }, [sencoEal, currentStudentId, teacherid]);

  useEffect(() => {
    if (student.id) {
      getSencoReportByStudentId(student.id)
        .then((response) => {
          if (response) {
            setCallClassName(response.classname || "");
            setSetName(response.setname || "");
            setClassroomSupportPlan(response.classroomsupportplan || false);
            setSencoEal(response.eal || false);
            setSet(response.set || false);
            setPlaceOnContinuum(response.supportcontinuum || []);
            setSencoAdditionalNeeds(response.additionalneeds || []);
            setPriorityConcerns(response.priorityconcerns || []);
            setSetReceived(response.setreceived || []);
            setSencoSupportPlan(response.supportplan || []);
          }
        })
        .catch((error) => console.error("Error fetching JiSi report:", error));
    }
  }, [student.id]);

  const getButtonStyle = (buttonClicked, dataExists) => {
    return buttonClicked || dataExists ? { backgroundColor: "#ffffcc" } : {};
  };

  const getCircularButtonStyle = (
    buttonClicked,
    dataExists,
    borderColor = "black"
  ) => {
    const size = "40px";
    return {
      borderRadius: "50%",
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      margin: "auto",
      border: `2px solid ${borderColor}`,
      backgroundColor: "white",
    };
  };

  return (
    <tr
      key={student.id}
      className="table-row senco-table-row"
      data-student-id={student.id}
    >
      <td className="centre-student-items align-left">{student.name}</td>
      <td className="centre-student-items task-badge-parent">
        {student.readingScore !== undefined && student.readingScore !== null ? (
          student.readingScore === "No completed tasks" ? (
            <span className="reading-score">{student.readingScore}</span>
          ) : (
            <span
              className={`reading-score ${getReadingScoreClass(
                student.readingScore
              )}`}
            >
              {student.readingScore}%
            </span>
          )
        ) : null}
      </td>
      {dummyHeaderArray.map((header, index) => (
        <td key={index} className="centre-student-items">
          {header === "Classroom Support Plan" ? (
            <button
              style={getButtonStyle(
                classroomSupportPlanModalClicked,
                classroomSupportPlan === true
              )}
              className="white-button"
              onClick={() => {
                handleShowClassroomSupportPlanModal(student.name, student.id);
                setClassroomSupportPlanClicked(true);
              }}
            >
              {classroomSupportPlanModalClicked ||
              classroomSupportPlan === true ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "Class" ? (
            <p>{callClassName}</p>
          ) : header === "SET" ? (
            <select
              value={setName}
              style={{ width: "100px" }}
              onChange={(e) => {
                setCurrentStudentId(student.id);
                setSetName(e.target.value);
              }}
            >
              <option value="">Select SET Teacher</option>
              {filteredTeachers.map((teacher) => (
                <option key={teacher.id} value={teacher.name}>
                  {teacher.name}
                </option>
              ))}
            </select>
          ) : header === "Details Of SET Received" ? (
            <button
              style={getButtonStyle(
                detailsOfSetReceivedButtonClicked,
                setReceived.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowDetailsOfSetReceivedModal(student.name, student.id);
                setDetailsOfSetReceivedButtonClicked(true);
              }}
            >
              {detailsOfSetReceivedButtonClicked || setReceived.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "Priority Concerns" ? (
            <button
              style={getButtonStyle(
                priorityConcernsButtonClicked,
                priorityConcerns.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowPriorityConcernsModal(student.name, student.id);
                setPriorityConcernsButtonClicked(true);
              }}
            >
              {priorityConcernsButtonClicked || priorityConcerns.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "Support Plan" ? (
            <button
              style={getButtonStyle(
                sencoSupportPlanButtonClicked,
                sencoSupportPlan.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowSencoSupportPlanModal(student.name, student.id);
                setSencoSupportPlanButtonClicked(true);
              }}
            >
              {sencoSupportPlanButtonClicked || sencoSupportPlan.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "Additional Needs" ? (
            <button
              style={getButtonStyle(
                additionalNeedsButtonClicked,
                sencoAdditionalNeeds.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowSencoAdditionalNeedsModal(student.name, student.id);
                setAdditionalNeedsButtonClicked(true);
              }}
            >
              {additionalNeedsButtonClicked ||
              sencoAdditionalNeeds.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "EAL" ? (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <input
                type="checkbox"
                checked={sencoEal}
                onChange={() => {
                  setCurrentStudentId(student.id);
                  setSencoEal((prev) => !prev);
                  setEalModalButtonClicked(true);
                }}
                style={{
                  appearance: "none",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid #2c7693",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              />
              {sencoEal && (
                <img
                  src="./assets/imgs/greenTick.svg"
                  alt="Checked"
                  style={{ width: "15px", position: "absolute" }}
                />
              )}
            </label>
          ) : header === "Place on Continuum" ? (
            <button
              style={getButtonStyle(
                placeOnContinuumButtonClicked,
                placeOnContinuum.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowPlaceOnContinuumModal(student.name, student.id);
                setPlaceOnContinuumButtonClicked(true);
              }}
            >
              {placeOnContinuumButtonClicked || placeOnContinuum.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : (
            <button className="white-button">
              <i className="bi bi-pencil-square"></i>
            </button>
          )}
        </td>
      ))}
    </tr>
  );
}

export default SencoReportTableRow;
