import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import ReactToPrint from "react-to-print";

import StudentsResultsTable from "./StudentsResultsTable";

import "./PrintClassResults.css";

function PrintClassResults(props) {
  let classDetails = props.classDetails;
  let classAssessments = props.classAssessments;
  let classAssessmentsArray = props.classAssessmentsArray;
  let teachers = props.teachers;
  let assessment = props.assessment;
  let name = classDetails.name;
  let gradeLevel = classDetails.grade_level;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;
  let assessmentResultsByStudent = props.assessmentResultsByStudent;
  let toggleScoreType = props.toggleScoreType;
  let sortCriteria = props.sortCriteria;
  let showAtRiskOnly = props.showAtRiskOnly;

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const currentYear = currentDate.getFullYear();

  let assessmentName = assessment.name;
  console.log(assessmentName, "Print");
  // console.log(classAssessments[0].name)
  const componentRef = useRef();

  // console.log(sortCriteria)
  // console.log(showAtRiskOnly)

  return (
    <>
      <ClassPrintable
        // students={studentsQRDetails}
        classDetails={classDetails}
        classAssessments={classAssessments}
        gradeLevel={gradeLevel}
        teachers={teachers}
        assessment={assessment}
        assessmentResultsByStudent={assessmentResultsByStudent}
        toggleScoreType={toggleScoreType}
        name={name}
        assessmentName={assessmentName}
        currentMonth={currentMonth}
        currentYear={currentYear}
        sortCriteria={sortCriteria}
        showAtRiskOnly={showAtRiskOnly}
        ref={componentRef}
      />

      <ReactToPrint
        content={() => componentRef.current}
        trigger={() => (
          <button className="blue-button" style={{ borderRadius: "8px" }}>
            Print
          </button>
        )}
      />
    </>
  );
}

const ClassPrintable = React.forwardRef((props, ref) => (
  <div className="hide-div landscape-print">
    <div ref={ref}>
      <Row className="mt-5 text-center">
        <Col className="printout-logo col-2"></Col>
        <Col className="col-4">
          <h3 className="printout-page-description-header">{props.name}</h3>
        </Col>
        <Col className="col-4">
          <h3 className="printout-page-description-header">
            {props.assessmentName}
          </h3>
        </Col>
        <Col className="col-2">
          <h3 className="printout-page-description-header">
            {props.currentMonth} {props.currentYear}
          </h3>
        </Col>
      </Row>
      <Row>
        <StudentsResultsTable
          classDetails={props.classDetails}
          classAssessments={props.classAssessments}
          gradeLevel={props.gradeLevel}
          teachers={props.teachers}
          assessment={props.assessment}
          assessmentResultsByStudent={props.assessmentResultsByStudent}
          toggleScoreType={props.toggleScoreType}
          sortCriteria={props.sortCriteria}
          showAtRiskOnly={props.showAtRiskOnly}
          isPrintOut={true}
        />
      </Row>
    </div>
  </div>
));

export default PrintClassResults;
