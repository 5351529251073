import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Col, Row, Container, Modal, Button } from "react-bootstrap";

import "./ClassOverview.css";
import "./SencoReportsOverview.css";

import GradeCard from "./GradeCard";
import ReturnToResults from "./ReturnToResults";
import PrintSencoReports from "./PrintSencoReports";
import SencoReportClassTable from "./SencoReportClassTable";
import ExpandedSencoReportView from "./ExpandedSencoReportView";

import { getResultsByStudent, getReportOptions } from "../services/APIClient";

function SencoReportsOverview(props) {
  console.log(props);

  let classDetails = props.classDetails;
  let classid = props.classid;
  //let classAssessments = props.assessmentInstanceID;
  let classAssessments = props.classAssessments;
  let gradeLevel = props.gradeLevel;
  let teachers = props.teachers;
  let teacherid = props.teacherid;
  let assessment = props.assessment;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;
  let students = props.students;
  let studentsReadingScore = props.studentsReadingScore;
  let nameOfClass = classDetails.name;
  let schoolid = props.schoolid;
  console.log(students, "HIIIIIIIII");

  console.log("studentsReadingScore:", studentsReadingScore);
  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState(
    []
  );

  let [reportOptions, setReportOptions] = useState("");

  const [showExpandedModal, setShowExpandedModal] = useState(false);
  const handleShowExpandedModal = () => setShowExpandedModal(true);
  const handleCloseExpandedModal = () => setShowExpandedModal(false);

  const [sortCriteria, setSortCriteria] = useState({
    field: null,
    order: null,
  });
  const [showAtRiskOnly, setShowAtRiskOnly] = useState(false);
  const [showSortedData, setShowSortedData] = useState(false);

  const handleShowSortedData = (data) => {
    setShowSortedData(data);
  };

  console.log(students, "students");

  useEffect(() => {
    if (assessment && assessment.instances) {
      getResultsByStudent(assessment.instances).then((response) => {
        setAssessmentResultsByStudent(response);
      });
    }
  }, [assessment]);

  useEffect(() => {
    getReportOptions(false).then((response) => {
      setReportOptions(response);
    });
  }, []);

  console.log(reportOptions);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-6">
            <Row>
              <Col className="col-8">
                <h2 className="page-description-header">SENCO Report</h2>
              </Col>
              <Col className="col-4">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
            </Row>
            <p>Use dropdown and edit buttons to generate a SENCO report</p>
          </Col>
          <Col className="col-4">
            <Row>
              <Col className="col-3"></Col>
              <Col className="col-4"></Col>
              <Col className="col-1"></Col>
              <Col className="col-4">
                <ReturnToResults
                  classDetails={classDetails}
                  teachers={teachers}
                  classAssessments={classAssessments}
                  assessment={assessment}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              </Col>

              <Col className="col-6"></Col>
            </Row>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="col-3">
            <button className="blue-button" onClick={handleShowExpandedModal}>
              Expanded View
            </button>
          </Col>
          <Col className="col-3">
            <PrintSencoReports
              students={students}
              classDetails={classDetails}
              classid={classid}
              classAssessments={classAssessments}
              gradeLevel={gradeLevel}
              teachers={teachers}
              teacherid={teacherid}
              assessment={assessment}
              assessmentResultsByStudent={assessmentResultsByStudent}
              isPrintOut={false}
              sortCriteria={sortCriteria}
              showAtRiskOnly={showAtRiskOnly}
              setSortCriteria={setSortCriteria}
              setShowAtRiskOnly={setShowAtRiskOnly}
              studentsReadingScore={studentsReadingScore}
              showSortedData={showSortedData}
              onSortData={handleShowSortedData}
            />
          </Col>
          <Col className="col-3">
            {/* <ExportClassResults
              classDetails={classDetails}
              classAssessments={classAssessments}
              gradeLevel={gradeLevel}
              teachers={teachers}
              assessment={assessment}
              assessmentResultsByStudent={assessmentResultsByStudent}
              toggleScoreType={toggleScoreType} /> */}
          </Col>
        </Row>
        <Row className="button-container">
          <Col className="col-12">
            <SencoReportClassTable
              students={students}
              classDetails={classDetails}
              classAssessments={classAssessments}
              gradeLevel={gradeLevel}
              teachers={teachers}
              teacherid={teacherid}
              assessment={assessment}
              assessmentResultsByStudent={assessmentResultsByStudent}
              isPrintOut={false}
              sortCriteria={sortCriteria}
              showAtRiskOnly={showAtRiskOnly}
              setSortCriteria={setSortCriteria}
              setShowAtRiskOnly={setShowAtRiskOnly}
              studentsReadingScore={studentsReadingScore}
              setShowSortedData={setShowSortedData}
              onSortData={handleShowSortedData}
              showSortedData={showSortedData}
            />
            {/* <ReportClassTable /> */}
          </Col>
        </Row>
        <Modal
          show={showExpandedModal}
          onHide={handleCloseExpandedModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-modal-width senco-expandable-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Expanded View of SENCO Report - {nameOfClass}
              <PrintSencoReports
                students={students}
                classDetails={classDetails}
                classid={classid}
                classAssessments={classAssessments}
                gradeLevel={gradeLevel}
                teachers={teachers}
                teacherid={teacherid}
                assessment={assessment}
                assessmentResultsByStudent={assessmentResultsByStudent}
                isPrintOut={false}
                sortCriteria={sortCriteria}
                showAtRiskOnly={showAtRiskOnly}
                setSortCriteria={setSortCriteria}
                setShowAtRiskOnly={setShowAtRiskOnly}
                studentsReadingScore={studentsReadingScore}
                showSortedData={showSortedData}
                onSortData={handleShowSortedData}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExpandedSencoReportView
              students={students}
              classDetails={classDetails}
              classid={classid}
              classAssessments={classAssessments}
              gradeLevel={gradeLevel}
              teachers={teachers}
              teacherid={teacherid}
              assessment={assessment}
              assessmentResultsByStudent={assessmentResultsByStudent}
              sortCriteria={sortCriteria}
              showAtRiskOnly={showAtRiskOnly}
              setSortCriteria={setSortCriteria}
              setShowAtRiskOnly={setShowAtRiskOnly}
              studentsReadingScore={studentsReadingScore}
              // setShowSortedData={setShowSortedData}
              onSortData={handleShowSortedData}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="blue-button" onClick={handleCloseExpandedModal}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col className="results-buttons"></Col>
        </Row>
      </Container>
    </div>
  );
}

export default SencoReportsOverview;
