import React from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// import button from 'react-bootstrap/button';

import "./ShareClassButton.css";

function InviteColleaguesButton(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let name = props.name;
  let gradeLevel = props.gradeLevel;
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let schoolid = props.schoolid;

  console.log(gradeLevel, "CCCCCCDDDDDDDD");

  return (
    <Link
      to={`/invitecolleagues`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        sharedWith: { sharedWith },
        name: { name },
        gradeLevel: { gradeLevel },
        rollNumber: { rollNumber },
        teacherEmail: { teacherEmail },
        schoolid: { schoolid },
      }}
    >
      <button
        className="blue-button"
        style={{
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          width: "100%",
        }}
      >
        Add Colleagues
      </button>
    </Link>
  );
}

export default InviteColleaguesButton;
